<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Masters</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper">
          <el-input
            placeholder="Search"
            v-model="search"
            class="input-with-select"
            clearable
          >
          
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button type="primary" @click="handleCreate()">Add</el-button>

        </div>
      </el-col>
    </el-row>
    <Table
      tableKey="traderFunds"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :showSummary="false"
      :tableActionVisibility="false"
      :tableActionViewVisibility="true"
      :tableActionEditVisibility="true"
      :tableActionDeleteVisibility="true"
      :tableLoadingStatus.sync="loading"
      :tablePagination="tablePagination"
       @edit="handleEdit($event)"
       @view="handleViewDetails($event)"
       @pagination="handlePagination()"
       @delete="handleDelete($event)"
    />
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import AddEditDialog from "@/views/notifications/components/AddEditDialog.vue";
// import getRoleSlug from "@/store/app.js";
import { fetchList, destroy } from "@/api/super-admin/admin";
import { _ } from "vue-underscore";


export default {
  name: "ListTraderFunds",
  components: {
    Table,
    AddEditDialog,
  },
  data() {
    return {
      dialogVisible: false,
      dialogType: "create",
      loading: false,
      search: "",
      listDataTemp: null,
      tablePagination: {},
      formData: {},
      formErrors: [],
      config: [
        {
          label: "ID",
          prop: "id",
          width: "100",
          className: "blueFont",
        },
        {
          label: "User Name",
          prop: "username",
          width: "",
          className: "blueFont",
          popover:true,
        },
        {
          label: "Name",
          prop: "name",
          width: "",
          className: "blueFont",
        },
        {
          label: "Email",
          prop: "email",
          width: "",
          className: "blueFont",
        },
        {
          label: "Phone #",
          prop: "phone",
          width: "",
          className: "blueFont",
        },
      ],
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      listData: {
        data: {
          item: [],
        },
      },
    };
  },
  computed: {},
  created() {
    this.getList();
    console.log(" hgdsqwguyqw hygtwqeute ghfqwyer")
  },
  methods: {
    handleViewDetails(data) {
       console.log(data)
      return this.$router.push("/super-admin/admins/user-view/" + data.id);
    },
     handleCreate() {
      return this.$router.push("/super-admin/admins/user-add");
      // return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/user-add");
    },
      handleEdit(id) {
      return this.$router.push("/super-admin/admins/user-edit/" +id );
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
    // //////////////////
    // HANDLE SEARCH
    // //////////////////
    handleSearch() {
      this.listData.data.item = this.listDataTemp;
      let s = this.search;
      s = s.toLowerCase();
      this.listData.data.item = _.filter(this.listData.data.item, function (d) {
        return d["username"] && d["username"].toLowerCase().includes(s);
      });
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        this.loading = false;
      })
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
    },
    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      console.log(data.id);
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        destroy(data.id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }else{
            this.flashError(response.data.message);
          }
        });
      });
    },
  },
};
</script>
